<template>
    <div>
        <el-divider></el-divider>
        <el-row>
            {{ $t("lang.日期") }}：<el-date-picker v-model="searchTime" @change="getList"></el-date-picker>
        </el-row>
        <el-col :span="13">
            <el-table ref="refElTable" :data="tableData" v-loading="tableLoading" highlight-current-row
                @current-change="loadDetail">
                <el-table-column :label="$t('lang.序列号')" prop="serialNumber" width="100"></el-table-column>
                <el-table-column :label="$t('lang.试验编号')" prop="shiYanBianHao" width="80" align="right"></el-table-column>
                <el-table-column :label="$t('lang.截面积')" prop="jieMianJi" align="right"
                    :formatter="v => { return v.jieMianJi + 'cm²'; }"></el-table-column>
                <el-table-column :label="$t('lang.表面积')" prop="biaoMianJi" align="right"
                    :formatter="v => { return v.biaoMianJi + 'cm²'; }"></el-table-column>
                <el-table-column :label="$t('lang.位移点长度')" prop="weiYiDianChangDu" width="100" align="right"
                    :formatter="v => { return v.weiYiDianChangDu + 'mm'; }"></el-table-column>
                <el-table-column :label="$t('lang.起始深度')" prop="qiShiShenDu" width="100" align="right"
                    :formatter="v => { return v.qiShiShenDu + 'm'; }"></el-table-column>
                <el-table-column :label="$t('lang.试验时间')" prop="insertTime" width="150"></el-table-column>
                <el-table-column :label="$t('lang.操作')" width="135" align="center">
                    <template #default="scope">
                        <el-button-group>
                            <el-button type="primary" size="mini" @click="print(scope.row)"> {{ $t("lang.打印") }}</el-button>
                            <el-button type="danger" size="mini" @click="del(scope.row)"> {{ $t("lang.删除") }}</el-button>
                        </el-button-group>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination @current-change="getList" background :total="total"
                :current-page.sync="currentPage"></el-pagination>
        </el-col>
        <el-col :span="11">
            <el-tabs stretch value="0">
                <el-tab-pane :label="$t('lang.锥尖阻力')" name="0">
                    <div id="GongZuoMoShi3Chart1"></div>
                </el-tab-pane>
                <el-tab-pane :label="$t('lang.侧壁阻力')" name="1">
                    <div id="GongZuoMoShi3Chart2"></div>
                </el-tab-pane>
                <el-tab-pane :label="$t('lang.详细信息')" name="2">
                    <el-table :data="detailData" max-height="600" border>
                        <el-table-column :label="$t('lang.深度')" prop="shenDu" align="right"
                            :formatter="v => { return v.shenDu + 'm'; }"></el-table-column>
                        <el-table-column :label="$t('lang.锥尖阻力')" prop="zhuiJianZuLi" align="right"
                            :formatter="v => { return v.zhuiJianZuLi + 'kPa'; }"></el-table-column>
                        <el-table-column :label="$t('lang.侧壁阻力')" prop="ceBiZuLi" align="right"
                            :formatter="v => { return v.ceBiZuLi + 'kPa'; }"></el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
        </el-col>
    </div>
</template>
<script>
import HighCharts from "highcharts";
export default {
    data() {
        return {
            currentPage: 1,
            total: 0,
            tableLoading: false,
            searchTime: null,
            tableData: null,
            detailData: [],
        };
    },
    methods: {
        getList() {
            let that = this;
            that.tableLoading = true;
            that.axios
                .post("JLCT/GetMoShi3List", {
                    currentPage: that.currentPage,
                    total: that.total,
                    onlyDateTime: that.searchTime,
                })
                .then(function (response) {
                    that.tableLoading = false;
                    that.total = response.data.data.total;
                    that.tableData = response.data.data.data;
                    if (that.tableData.length > 0) that.$refs.refElTable.setCurrentRow(that.tableData[0]);
                });
        },
        del(row) {
            let that = this;
            that.$confirm(that.$t("lang.确定要删除吗"))
                .then(function () {
                    that.axios.post("JLCT/DelJLCT3", { onlyText: row.id }).then(() => {
                        that.getList();
                    });
                })
                .catch(function () { });
        },
        print(row) {
            open(process.env.VUE_APP_TurnUrl + "GongZuoMoShi3/print?id=" + row.id + "&&lang=" + window.localStorage.getItem("lang"));
        },
        loadDetail(row) {
            let that = this;
            var arr = row.zhuiJianZuLi.split(",");
            var arr1 = row.shenDu.split(",");
            var arr2 = row.ceBiZuLi.split(",");
            var arrData = [{ name: that.$t("lang.深度锥尖阻力"), data: [] }];
            var arrData1 = [{ name: that.$t("lang.深度侧壁阻力"), data: [] }];
            var arrData2 = [];
            for (var i = 0; i < arr.length - 1; i++) {
                arrData[0].data.push([parseFloat(arr[i]), -parseFloat(arr1[i])]);
                arrData1[0].data.push([parseFloat(arr2[i]), -parseFloat(arr1[i])]);
                arrData2.push({
                    shenDu: -parseFloat(arr1[i]),
                    ceBiZuLi: arr2[i],
                    zhuiJianZuLi: arr[i],
                });
            }
            that.loadMap1(arrData, row.shiYanBianHao);
            that.loadMap2(arrData1, row.shiYanBianHao);
            that.detailData = arrData2;
        },
        loadMap1(data, shiYanBianHao) {
            let that = this;
            HighCharts.chart("GongZuoMoShi3Chart1", {
                lang: {
                    viewFullscreen: that.$t("lang.全屏"),
                    printChart: that.$t("lang.打印图表"),
                    downloadJPEG: that.$t("lang.导出JPEG"),
                    downloadPDF: that.$t("lang.导出PDF"),
                    downloadPNG: that.$t("lang.导出PNG"),
                    downloadSVG: that.$t("lang.导出SVG"),
                },
                title: {
                    text: that.$t("lang.试验编号") + "(" + shiYanBianHao + ")",
                },
                tooltip: {
                    formatter: function () {
                        return that.$t("lang.锥尖阻力") + "：<b>" + this.x + "</b>kPa<br />" + that.$t("lang.深度") + "：<b>" + this.y + "</b>m";
                    },
                },
                xAxis: {
                    title: {
                        text: that.$t("lang.锥尖阻力") + "(kPa)",
                    },
                    opposite: true,
                },
                yAxis: {
                    title: {
                        text: that.$t("lang.深度") + "(m)",
                    },
                },
                series: data,
                credits: {
                    enabled: false,
                },
            });
        },
        loadMap2(data, shiYanBianHao) {
            let that = this;
            HighCharts.chart("GongZuoMoShi3Chart2", {
                lang: {
                    viewFullscreen: that.$t("lang.全屏"),
                    printChart: that.$t("lang.打印图表"),
                    downloadJPEG: that.$t("lang.导出JPEG"),
                    downloadPDF: that.$t("lang.导出PDF"),
                    downloadPNG: that.$t("lang.导出PNG"),
                    downloadSVG: that.$t("lang.导出SVG"),
                },
                title: {
                    text: that.$t("lang.试验编号") + "(" + shiYanBianHao + ")",
                },
                tooltip: {
                    formatter: function () {
                        return that.$t("lang.侧壁阻力") + "：<b>" + this.x + "</b>kPa<br />" + that.$t("lang.深度") + "：<b>" + this.y + "</b>m";
                    },
                },
                xAxis: {
                    title: {
                        text: that.$t("lang.侧壁阻力") + "(kPa)",
                    },
                    opposite: true,
                },
                yAxis: {
                    title: {
                        text: that.$t("lang.深度") + "(m)",
                    },
                },
                plotOptions: {
                    //series: {
                    //    turboThreshold: 0
                    //}
                },
                series: data,
                credits: {
                    enabled: false,
                },
            });
        },
    },
    mounted() {
        let that = this;
        that.getList();
    },
};
</script>

<style></style>